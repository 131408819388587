#logo {
    position: absolute;
    top: 10mm;
    left: 10mm;
    padding: 3mm;
    background-color: #212529;
    color: #f8f9fa;
    font-size: x-large;
    font-weight: bold;
    border: solid gray;
    border-radius: 10%;
}
#assigner-logo {
    position: absolute;
    top: 10mm;
    right: 10mm;
    width: 60mm;
}
#assigner-name {
    font-weight: bold;
}
#certificate-info {
    margin-top: 70mm;
}


@media screen {
    #print-area {
        position: relative;
        top: 0;
        width: 210mm;
        height: 297mm;
        padding: 10mm;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        border: 1px solid black;
    }

    #about-menschid {
        position: absolute;
        width: 190mm;
        bottom: 10mm;
    }
    #certificate-info {
        width: 190mm;
    }
}

@media print {

    .no-print {
        display: none !important;
    }

    #print-area {
        position: absolute;
        top: 0;
        right: 0;
    }

    #about-menschid {
        position: fixed;
        bottom: 0mm;
    }

    @page {
        size: A4;
        orientation: portrait;
        margin: 10mm 10mm 10mm 10mm;
    }

}